import { Injectable, OnDestroy } from '@angular/core';
import { PersonalisationPersonalData } from '@types';
import { Logger } from '@utils/logger';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDFlagSet, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvConfigService } from './env-config.service';

const logger = Logger.getLogger('LaunchDarklyService');

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService implements OnDestroy {
  private client: LaunchDarkly.LDClient;
  private flags: LDFlagSet = null;
  public isInitialised: boolean;

  private flagChange$: BehaviorSubject<LDFlagSet> = new BehaviorSubject<LDFlagSet>(
    null
  );

  constructor(private envConfigService: EnvConfigService) {}

  /**
   * initialize the launch darkly
   */
  public initialize(userData: PersonalisationPersonalData) {
    this.isInitialised = true;
    const user = {
      kind: 'user',
      key: userData.identifiers.globalId,
      type: userData.clientType,
    } as LaunchDarkly.LDContext;
    this.client = LaunchDarkly.initialize(
      this.envConfigService.getEnvConfig().launchDarklyKey,
      user
    );
    this.client
      .waitUntilReady()
      .then(() => {
        this.setFlags();
      })
      .catch((err) => {
        logger.error('launch darkly intialization error : ', err);
      });
  }

  /**
   * get all the flags
   */
  public getFlags$(): Observable<LDFlagValue> {
    return this.flagChange$.asObservable();
  }

  /**
   * track the button click event in the launch darkly for experimentation
   */
  public trackClick(metricsEventKey: string): void {
    // metrics event key and feature flag key both should be same.
    this.client?.track(metricsEventKey);
  }

  private setFlags() {
    this.flags = this.client.allFlags();
    this.flagChange$.next(this.flags);
    this.client.on('ready', (flags) => {
      this.flags = flags;
      this.flagChange$.next(this.flags);
    });

    this.client.on(`change`, (flags) => {
      this.flags = flags;
      this.flagChange$.next(this.flags);
    });
  }

  async ngOnDestroy() {
    await this.client?.close();
  }
}
